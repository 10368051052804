import { Disc, Globe, Image, Layers, Package, Radio, Smartphone, Sun, Users, Wifi } from "react-feather";

// menus for root user
const trackingSection = [
  {
    href: "/assets",
    icon: Package,
    title: "Assets",
  },
  {
    href: "/archived-assets",
    icon: Package,
    title: "Archived Assets"
  },
  {
    href: "/people",
    icon: Users,
    title: "People",
  },
  {
    href: "/zones",
    icon: Disc,
    title: "Zones",
  }
];

const devicesSection = [
  {
    href: "/deviceProfiles",
    icon: Layers,
    title: "Device Profiles",
  },
  {
    href: "/trackers",
    icon: Globe,
    title: "Trackers",
  },
  {
    href: "/readers",
    icon: Wifi,
    title: "Readers",
  },
  {
    href: "/tags",
    icon: Radio,
    title: "Tags",
  },
  {
    href: "/apps",
    icon: Smartphone,
    title: "Apps",
  },
  {
    href: "/solarchargers",
    icon: Sun,
    title: "Solar Chargers",
  },
  {
    href: "/floorplans",
    icon: Image,
    title: "Floorplans",
  },
];

const adminSection = [
  {
    href: "/customers",
    icon: Users,
    title: "Customers",
  }
];

const navItems = [
  {
    title: "Root Admin",
    pages: adminSection,
  },
  {
    title: 'Tracking',
    pages: trackingSection
  },
  {
    title: "Devices",
    pages: devicesSection,
  },
];

export default navItems;
